import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Textarea,
} from '@pfida-finance/ui/components';
import {
  PutUserApplicationAgreementreferenceApplicationidMutationRequest,
  usePutUserApplicationAgreementreferenceApplicationid,
} from '@repo/api/services/dipComponent/gen';
import { getRouteApi } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { Error } from '../components/error';
import { useDipApplicationFormContext } from '../context/dipApplicationFormContext';
import { trueFalseEnum, yesNoEnum } from '../schema/common';
import {
  financePurposeEnum,
  productTypeEnum,
  propertyAndFinanceSchema,
  propertyLocationEnum,
  type PropertyAndFinanceSchema,
} from '../schema/propertyAndFinanceSchema';
import { convertStringsToBooleans } from '../utils/mapFormData';
import { onNumberInputChange } from '../utils/onNumberInputChange';

const routeApi = getRouteApi('/_authenticated/_pfida/dip');

export function PropertyAndFinance() {
  const { changeCurrentStep, formState, updateFormState, currentPageNumber } = useDipApplicationFormContext();

  const { agreementReference, applicationId } = routeApi.useSearch();

  const dipMutation = usePutUserApplicationAgreementreferenceApplicationid(
    agreementReference.toString(),
    applicationId
  );

  const form = useForm<PropertyAndFinanceSchema>({
    resolver: zodResolver(propertyAndFinanceSchema),
    defaultValues: formState.propertyAndFinance,
  });

  const productType = form.watch('productType');

  const onSubmit = (data: PropertyAndFinanceSchema) => {
    updateFormState('propertyAndFinance', data);

    const fullFormData = { ...formState, propertyAndFinance: { ...data }, timeFrame: formState.timeFrame.timeframe };
    const formattedData = convertStringsToBooleans(fullFormData);

    dipMutation.reset();
    dipMutation.mutate(formattedData as PutUserApplicationAgreementreferenceApplicationidMutationRequest, {
      onSuccess: () => {
        changeCurrentStep('end', currentPageNumber + 1);
      },
    });
  };

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
          <div className="flex items-center justify-center text-sm font-bold h-6 w-6 rounded-full bg-accent text-accent-foreground">
            {currentPageNumber}
          </div>
          Property and finance
        </CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <Form {...form}>
        <form className="flex flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="flex flex-col p-0 gap-10">
            <FormField
              control={form.control}
              name="productType"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">What kind of product are you looking for?</FormLabel>
                    <FormDescription>
                      If you are unsure about our product range, please visit our website for more information:{' '}
                      <a
                        className="underline text-primary"
                        target="_blank"
                        href="https://www.pfida.com/"
                        rel="noreferrer"
                      >
                        www.pfida.com
                      </a>
                    </FormDescription>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={productTypeEnum.Enum['Buy-to-Let Purchase Plan']} />
                        </FormControl>
                        <FormLabel className="leading-5">{productTypeEnum.Enum['Buy-to-Let Purchase Plan']}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={productTypeEnum.Enum['Home Provision Scheme']} />
                        </FormControl>
                        <FormLabel className="leading-5">{productTypeEnum.Enum['Home Provision Scheme']}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {productType && (
              <>
                {productType === productTypeEnum.Enum['Home Provision Scheme'] && (
                  <FormField
                    control={form.control}
                    name="isPropertyMainResidence"
                    render={({ field, fieldState }) => (
                      <FormItem className="flex flex-col gap-3 space-y-0 md:col-span-2">
                        <div className="flex flex-col gap-1">
                          <FormLabel className="leading-5">
                            Will this property be the primary residence for all applicant(s)?
                          </FormLabel>
                        </div>
                        <FormControl>
                          <RadioGroup
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                            invalid={fieldState.invalid}
                            className="flex flex-col gap-2"
                          >
                            <FormItem className="flex items-center gap-2 space-y-0">
                              <FormControl>
                                <RadioGroupItem value={trueFalseEnum.Enum.true} />
                              </FormControl>
                              <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                            </FormItem>
                            <FormItem className="flex items-center gap-2 space-y-0">
                              <FormControl>
                                <RadioGroupItem value={trueFalseEnum.Enum.false} />
                              </FormControl>
                              <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                            </FormItem>
                          </RadioGroup>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name="financePurpose"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>What is the purpose of finance?</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger invalid={fieldState.invalid}>
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={financePurposeEnum.Enum['New Purchase']}>
                            {financePurposeEnum.Enum['New Purchase']}
                          </SelectItem>
                          {productType === productTypeEnum.Enum['Buy-to-Let Purchase Plan'] && (
                            <SelectItem value={financePurposeEnum.Enum['Refinance Buy-to-let Property']}>
                              {financePurposeEnum.Enum['Refinance Buy-to-let Property']}
                            </SelectItem>
                          )}
                          <SelectItem value={financePurposeEnum.Enum.Other}>{financePurposeEnum.Enum.Other}</SelectItem>
                        </SelectContent>
                      </Select>
                      {productType === productTypeEnum.Enum['Home Provision Scheme'] && (
                        <FormDescription>
                          We are not currently offering refinance under Home Provision Scheme.
                        </FormDescription>
                      )}
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="propertyLocation"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-3 space-y-0">
                      <div className="flex flex-col gap-1">
                        <FormLabel className="leading-5">Property location</FormLabel>
                        <FormDescription>
                          We are not currently offering property finance for Northern Ireland.
                        </FormDescription>
                      </div>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          invalid={fieldState.invalid}
                          className="flex flex-col gap-2"
                        >
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={propertyLocationEnum.Enum.England} />
                            </FormControl>
                            <FormLabel className="leading-5">{propertyLocationEnum.Enum.England}</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={propertyLocationEnum.Enum.Scotland} />
                            </FormControl>
                            <FormLabel className="leading-5">{propertyLocationEnum.Enum.Scotland}</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center gap-2 space-y-0">
                            <FormControl>
                              <RadioGroupItem value={propertyLocationEnum.Enum.Wales} />
                            </FormControl>
                            <FormLabel className="leading-5">{propertyLocationEnum.Enum.Wales}</FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="estimatedPropertyValue"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>What is the estimated property value?</FormLabel>
                      <Input
                        placeholder="e.g. 200000"
                        invalid={fieldState.invalid}
                        {...field}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="downPayment"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>
                        In Pounds Sterling (£), how much will you contribute towards the <b>down payment</b>?
                      </FormLabel>
                      <Input
                        placeholder="e.g. 50000"
                        invalid={fieldState.invalid}
                        {...field}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormDescription>This must include funds already held in your GYS account</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="termYears"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>Term required in years</FormLabel>
                      <Input
                        placeholder="e.g. 20"
                        invalid={fieldState.invalid}
                        {...field}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormDescription>Maximum term you can apply for is 30 years</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="additionalInformation"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>
                        Is there any information you would like to provide which would assist with our assessment of
                        your application?
                      </FormLabel>
                      <Textarea placeholder="Enter additional information" invalid={fieldState.invalid} {...field} />
                      <FormDescription>
                        This could be any criminal convictions or upcoming court proceedings, CCJs, Bankruptcy or any
                        foreseeable change in financial circumstances such as parental leave, redundancy, termination of
                        jobs.
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <p className="text-sm">
                  By submitting, I declare that to the best of my knowledge, the information disclosed on this form is
                  truthful and accurate.
                  <br />
                  <br />
                  If any of the information transpires to be inaccurate or false, I understand that my application could
                  be declined and if a full application is made, I may lose some or all of my arrangement fees.
                </p>
                <FormField
                  control={form.control}
                  name="signedBy"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>Signed by (Full Name of Applicant 1)</FormLabel>
                      <Input placeholder="Enter Applicant 1 name" invalid={fieldState.invalid} {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            {Object.keys(form.formState.errors).length > 0 && <Error />}
            {dipMutation.isError && (
              <Alert className="md:col-span-2" variant="destructive">
                <AlertTitle>There was an error submitting your form</AlertTitle>
                <AlertDescription>
                  Please check your answers and try again. If the issue persists, please contact{' '}
                  <a className="underline text-primary" target="_blank" rel="noreferrer" href="mailto:info@pfida.com">
                    info@pfida.com
                  </a>
                </AlertDescription>
              </Alert>
            )}
          </CardContent>
          <Separator className="my-4" />
          <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
            <Button
              variant="outline"
              className="w-full md:w-auto"
              size="lg"
              onClick={() => {
                dipMutation.reset();
                changeCurrentStep('outgoingsAndCommitments', currentPageNumber - 1);
              }}
            >
              Back
            </Button>
            <Button type="submit" variant="success" className="w-full md:w-auto" disabled={dipMutation.isPending}>
              {dipMutation.isPending ? 'Submitting' : 'Submit form'}
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
