import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Separator,
} from '@pfida-finance/ui/components';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Error } from '../components/error';
import { useDipApplicationFormContext } from '../context/dipApplicationFormContext';
import { trueFalseEnum, yesNoEnum } from '../schema/common';
import {
  outgoingsAndCommitmentsSchema,
  type OutgoingsAndCommitmentsSchema,
} from '../schema/outgoingsAndCommitmentsSchema';
import { onNumberInputChange } from '../utils/onNumberInputChange';

export function OutgoingsAndCommitments() {
  const { changeCurrentStep, formState, updateFormState, currentPageNumber } = useDipApplicationFormContext();

  const form = useForm<OutgoingsAndCommitmentsSchema>({
    resolver: zodResolver(outgoingsAndCommitmentsSchema),
    defaultValues: formState.outgoingsAndCommitments,
  });

  const totalMonthlyRent = form.watch('totalMonthlyRent');
  const financialCommitments = form.watch('financialCommitments');

  const hasSecondApplicant = formState.userDetails.hasSecondApplicant === 'true';

  useEffect(() => {
    if (totalMonthlyRent !== 'Other') {
      form.setValue('totalMonthlyRentOther', undefined);
    }
  }, [form, totalMonthlyRent]);

  useEffect(() => {
    if (financialCommitments !== 'true') {
      form.setValue('totalMonthlyPayment', undefined);
    }
  }, [form, financialCommitments]);

  const onSubmit = (data: OutgoingsAndCommitmentsSchema) => {
    updateFormState('outgoingsAndCommitments', data);
    changeCurrentStep('propertyAndFinance', currentPageNumber + 1);
  };

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
          <div className="flex items-center justify-center text-sm font-bold h-6 w-6 rounded-full bg-accent text-accent-foreground">
            {currentPageNumber}
          </div>
          Outgoings and Commitments
        </CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <Form {...form}>
        <form className="flex flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="grid md:grid-cols-2 p-0 gap-10">
            <FormLabel className="block text-foreground md:col-span-2 font-medium">
              If more than one applicant, please enter the joint totals for your outgoings and commitments.
            </FormLabel>
            <div className="grid gap-3 md:col-span-2">
              <FormField
                control={form.control}
                name="totalMonthlyRent"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col gap-3 space-y-0">
                    <div className="flex flex-col gap-1">
                      <FormLabel className="leading-5">
                        What will be your <b>total</b> monthly rent/board expenses <b>after</b> completion?
                      </FormLabel>
                    </div>
                    <FormControl>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        invalid={fieldState.invalid}
                        className="flex flex-col gap-2"
                      >
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="I will not pay any rent/board expense after completion" />
                          </FormControl>
                          <FormLabel className="leading-5">
                            I will not pay any rent/board expense after completion
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="Other" />
                          </FormControl>
                          <FormLabel className="leading-5">Other</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {totalMonthlyRent === 'Other' && (
                <FormField
                  control={form.control}
                  name="totalMonthlyRentOther"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <Input
                        placeholder="e.g. 1000"
                        invalid={fieldState.invalid}
                        {...field}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
            <FormField
              control={form.control}
              name="inRentArrears"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0 md:col-span-2">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">
                      In the last 24 months, have you ever been in rent arrears?
                    </FormLabel>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.true} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.false} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-2 md:col-span-2">
              <FormLabel className="leading-5">
                We need to know your monthly regular spending excluding rent, board and financial commitments. According
                to your bank statements, how much on average do you spend <b>each month</b> on the following:
              </FormLabel>
              <FormLabel className="leading-5 text-muted-foreground">
                For any annual expenses, please divide the amount by 12 to get monthly figure. If you are expecting a
                child, please also consider relevant post-birth expenses (e.g. childcare, groceries,...)
              </FormLabel>
            </div>
            <div className="grid md:grid-cols-[minmax(60%,_40%)] gap-5 md:col-span-2">
              <FormField
                control={form.control}
                name="councilTax"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Council Tax</FormLabel>
                    <Input
                      placeholder="e.g. 150"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="utilities"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Utilities</FormLabel>
                    <Input
                      placeholder="e.g. 150"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include gas, electric and water utility bills</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="insurance"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Insurance</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include home, car, gadget, life and health insurance</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subscriptions"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Subscriptions</FormLabel>
                    <Input
                      placeholder="e.g. 50"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>
                      Include phone, internet, TV license and streaming services subscriptions
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="childcareAndSchool"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Childcare, school fees and higher education</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include uniforms and stationery</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="groceries"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Groceries</FormLabel>
                    <Input
                      placeholder="e.g. 200"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="eatingOut"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Eating out</FormLabel>
                    <Input
                      placeholder="e.g. 200"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include dining, takeaway and coffees</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="householdShopping"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Household shopping</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include cleaning and bathroom items</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="otherShopping"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Other shopping or regular purchases</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include clothing and toys</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lifestyle"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Lifetstyle</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include hobbies, gym and cinema</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="travel"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Travel</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include petrol, parking and public transport</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="holidays"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Holidays</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="transfers"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Transfers to others</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="medical"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Medical</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormDescription>Include prescriptions, excluding health insurance</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="childMaintenance"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>Child maintenance or payments to support adult dependents</FormLabel>
                    <Input
                      placeholder="e.g. 100"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="financialCommitments"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0 md:col-span-2">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">Do you have any financial commitments?</FormLabel>
                    <FormDescription>
                      e.g. credit cards, mortgages or home financing, personal loans, hire purchase agreements, lease
                      agreements, finance agreements, guarantees, informal loans, overdrafts, buy now pay later plans,
                      payday loans, or advances against income
                    </FormDescription>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.true} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.false} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {financialCommitments === 'true' && (
              <FormField
                control={form.control}
                name="totalMonthlyPayment"
                render={({ field, fieldState }) => (
                  <FormItem className="md:col-span-2">
                    <FormLabel>
                      What is the <b>total monthly payment</b> of your other financial commitments?
                    </FormLabel>
                    <Input
                      placeholder="e.g. 1000"
                      invalid={fieldState.invalid}
                      {...field}
                      onChange={(e) => onNumberInputChange(e, field)}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name="financialDifficulty"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0 md:col-span-2">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">Have you ever been in financial difficulty?</FormLabel>
                    <FormDescription>
                      e.g. arrears, defaults, CCJs, bankruptcy, Individual Voluntary Arrangement (IVA), debt relief
                      order, debt management plan, administration order or adverse credit
                    </FormDescription>
                  </div>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      invalid={fieldState.invalid}
                      className="flex flex-col gap-2"
                    >
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.true} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem value={trueFalseEnum.Enum.false} />
                        </FormControl>
                        <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {Object.keys(form.formState.errors).length > 0 && <Error />}
          </CardContent>
          <Separator className="my-4" />
          <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
            <Button
              variant="outline"
              className="w-full md:w-auto"
              size="lg"
              onClick={() =>
                changeCurrentStep(
                  hasSecondApplicant ? 'applicantTwoEmploymentDetails' : 'applicantOneEmploymentDetails',
                  currentPageNumber - 1
                )
              }
            >
              Back
            </Button>
            <Button type="submit" className="w-full md:w-auto" size="lg">
              Next
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
