import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import client from '../../client';
import type {
  PutUserApplicationAgreementreferenceApplicationid400,
  PutUserApplicationAgreementreferenceApplicationid404,
  PutUserApplicationAgreementreferenceApplicationid500,
  PutUserApplicationAgreementreferenceApplicationidMutationRequest,
  PutUserApplicationAgreementreferenceApplicationidMutationResponse,
  PutUserApplicationAgreementreferenceApplicationidPathParams,
} from '../types/PutUserApplicationAgreementreferenceApplicationid';
import { useInvalidationForMutation } from '../useInvalidationForMutation';

type PutUserApplicationAgreementreferenceApplicationidClient = typeof client<
  PutUserApplicationAgreementreferenceApplicationidMutationResponse,
  | PutUserApplicationAgreementreferenceApplicationid400
  | PutUserApplicationAgreementreferenceApplicationid404
  | PutUserApplicationAgreementreferenceApplicationid500,
  PutUserApplicationAgreementreferenceApplicationidMutationRequest
>;
type PutUserApplicationAgreementreferenceApplicationid = {
  data: PutUserApplicationAgreementreferenceApplicationidMutationResponse;
  error:
    | PutUserApplicationAgreementreferenceApplicationid400
    | PutUserApplicationAgreementreferenceApplicationid404
    | PutUserApplicationAgreementreferenceApplicationid500;
  request: PutUserApplicationAgreementreferenceApplicationidMutationRequest;
  pathParams: PutUserApplicationAgreementreferenceApplicationidPathParams;
  queryParams: never;
  headerParams: never;
  response: PutUserApplicationAgreementreferenceApplicationidMutationResponse;
  client: {
    parameters: Partial<Parameters<PutUserApplicationAgreementreferenceApplicationidClient>[0]>;
    return: Awaited<ReturnType<PutUserApplicationAgreementreferenceApplicationidClient>>;
  };
};
/**
 * @description User API to submit the DIP Application form
 * @summary Submit DIP Application
 * @link /user/application/:agreementReference/:applicationId
 */
export function usePutUserApplicationAgreementreferenceApplicationid(
  agreementReference: PutUserApplicationAgreementreferenceApplicationidPathParams['agreementReference'],
  applicationId: PutUserApplicationAgreementreferenceApplicationidPathParams['applicationId'],
  options: {
    mutation?: UseMutationOptions<
      PutUserApplicationAgreementreferenceApplicationid['response'],
      PutUserApplicationAgreementreferenceApplicationid['error'],
      PutUserApplicationAgreementreferenceApplicationid['request']
    >;
    client?: PutUserApplicationAgreementreferenceApplicationid['client']['parameters'];
  } = {}
) {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
  const invalidationOnSuccess = useInvalidationForMutation('usePutUserApplicationAgreementreferenceApplicationid');
  return useMutation({
    mutationFn: async (data) => {
      const res = await client<
        PutUserApplicationAgreementreferenceApplicationid['data'],
        PutUserApplicationAgreementreferenceApplicationid['error'],
        PutUserApplicationAgreementreferenceApplicationid['request']
      >({
        method: 'put',
        url: `/user/application/${agreementReference}/${applicationId}`,
        data,
        ...clientOptions,
      });
      return res.data;
    },
    onSuccess: (...args) => {
      if (invalidationOnSuccess) invalidationOnSuccess(...args);
      if (mutationOptions?.onSuccess) mutationOptions.onSuccess(...args);
    },
    ...mutationOptions,
  });
}
