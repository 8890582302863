import { zodResolver } from '@hookform/resolvers/zod';
import { parseDate } from '@internationalized/date';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Checkbox,
  DateInput,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Textarea,
} from '@pfida-finance/ui/components';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Error } from '../components/error';
import { useDipApplicationFormContext } from '../context/dipApplicationFormContext';
import {
  ApplicantXEmploymentSchema,
  applicantXEmploymentSchema,
  employedTypeEnum,
  employmentTypeEnum,
  monthsLeftOnContractEnum,
  yearsOfIncomeProofEnum,
} from '../schema/applicantXEmploymentSchema';
import { trueFalseEnum, yesNoEnum } from '../schema/common';
import { onNumberInputChange } from '../utils/onNumberInputChange';

export function ApplicantXEmployment({ applicantNumber }: { applicantNumber?: '1' | '2' }) {
  const { changeCurrentStep, formState, updateFormState, currentPageNumber } = useDipApplicationFormContext();

  const applicant2Prefix = applicantNumber === '2' ? 'Applicant 2:' : '';

  const form = useForm<ApplicantXEmploymentSchema>({
    resolver: zodResolver(applicantXEmploymentSchema),
    defaultValues:
      applicantNumber === '1' ? formState.applicantOneEmploymentDetails : formState.applicantTwoEmploymentDetails,
    shouldUnregister: true,
  });

  const hasSecondApplicant = formState.userDetails.hasSecondApplicant === 'true';

  const anyOtherIncome = useWatch({ name: 'anyOtherIncome', control: form.control });
  const employmentType = useWatch({ name: 'employmentType', control: form.control });
  const yearsOfIncomeProof = useWatch({ name: 'yearsOfIncomeProof', control: form.control });
  const anyOtherEmployment = useWatch({ name: 'anyOtherEmployment', control: form.control });

  useEffect(() => {
    if (anyOtherIncome === 'false') {
      form.setValue('otherIncomeTypes', undefined);
      form.setValue('totalOtherIncomeAverage', undefined);
    }
  }, [form, anyOtherIncome]);

  useEffect(() => {
    if (
      ([employmentTypeEnum.Enum.Retired, employmentTypeEnum.Enum['No employment']] as string[]).includes(employmentType)
    ) {
      form.setValue('employmentStartDate', undefined);
      form.setValue('employedType', undefined);
      form.setValue('grossAnnualIncome', undefined);
      form.setValue('netMonthlyIncome', undefined);
      form.setValue('anyOtherEmployment', undefined);
      form.setValue('tradingLengthYears', undefined);
      form.setValue('yearsOfIncomeProof', undefined);
      form.setValue('grossIncomeRecentYear', undefined);
      form.setValue('netIncomeRecentYear', undefined);
      form.setValue('grossIncomePreviousYear', undefined);
      form.setValue('netIncomePreviousYear', undefined);
      form.setValue('monthsLeftOnContract', undefined);
      form.setValue('willContractBeRenewed', undefined);
    }

    if (employmentType === employmentTypeEnum.Enum.Employed) {
      form.setValue('tradingLengthYears', '');
      form.setValue('yearsOfIncomeProof', undefined);
      form.setValue('grossIncomeRecentYear', '');
      form.setValue('netIncomeRecentYear', '');
      form.setValue('grossIncomePreviousYear', '');
      form.setValue('netIncomePreviousYear', '');
      form.setValue('monthsLeftOnContract', undefined);
      form.setValue('willContractBeRenewed', undefined);
    }

    if (employmentType === employmentTypeEnum.Enum['Self-employed']) {
      form.setValue('employmentStartDate', undefined);
      form.setValue('employedType', undefined);
      form.setValue('monthsLeftOnContract', undefined);
      form.setValue('willContractBeRenewed', undefined);
      form.setValue('grossAnnualIncome', undefined);
      form.setValue('netMonthlyIncome', undefined);

      if (yearsOfIncomeProof === yearsOfIncomeProofEnum.Enum['1 year']) {
        form.setValue('grossIncomePreviousYear', undefined);
        form.setValue('netIncomePreviousYear', undefined);
      }
    }
    if (employmentType === employmentTypeEnum.Enum.Contractor) {
      form.setValue('employedType', undefined);
      form.setValue('tradingLengthYears', undefined);
      form.setValue('yearsOfIncomeProof', undefined);
      form.setValue('grossIncomeRecentYear', undefined);
      form.setValue('netIncomeRecentYear', undefined);
      form.setValue('grossIncomePreviousYear', undefined);
      form.setValue('netIncomePreviousYear', undefined);
    }
  }, [form, employmentType, yearsOfIncomeProof]);

  const onSubmit = (data: ApplicantXEmploymentSchema) => {
    if (applicantNumber === '1') {
      updateFormState('applicantOneEmploymentDetails', data);

      if (hasSecondApplicant) {
        changeCurrentStep('applicantTwoEmploymentDetails', currentPageNumber + 1);
      } else {
        changeCurrentStep('outgoingsAndCommitments', currentPageNumber + 1);
      }
    } else if (applicantNumber === '2') {
      updateFormState('applicantTwoEmploymentDetails', data);
      changeCurrentStep('outgoingsAndCommitments', currentPageNumber + 1);
    }
  };

  const onBack = () => {
    if (applicantNumber === '1') {
      changeCurrentStep('userDetails', currentPageNumber - 1);
    } else {
      changeCurrentStep('applicantOneEmploymentDetails', currentPageNumber - 1);
    }
  };

  const otherIncomeTypes = [
    'Rental income from rental properties',
    'Government benefits and allowances',
    'Pensions',
    'Investment income',
    'Income Protection Plan',
    'Income from Trusts',
    'Court Ordered Income',
  ];

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
          <div className="flex items-center justify-center text-sm font-bold h-6 w-6 rounded-full bg-accent text-accent-foreground">
            {currentPageNumber}
          </div>
          Applicant {hasSecondApplicant ? `${applicantNumber} ` : ''}employment details
        </CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <Form {...form}>
        <form className="flex flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="flex flex-col p-0 gap-10">
            <FormField
              control={form.control}
              name="employmentType"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0">
                  <FormLabel>{applicant2Prefix} Employment type</FormLabel>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    invalid={fieldState.invalid}
                    className="flex flex-col gap-2"
                  >
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem id="employed" value={employmentTypeEnum.Enum.Employed} />
                      </FormControl>
                      <FormLabel htmlFor="employed" className="leading-5">
                        {employmentTypeEnum.Enum.Employed}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem id="self-employed" value={employmentTypeEnum.Enum['Self-employed']} />
                      </FormControl>
                      <FormLabel htmlFor="self-employed" className="leading-5">
                        {employmentTypeEnum.Enum['Self-employed']}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem id="contractor" value={employmentTypeEnum.Enum.Contractor} />
                      </FormControl>
                      <FormLabel htmlFor="contractor" className="leading-5">
                        {employmentTypeEnum.Enum.Contractor}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem id="retired" value={employmentTypeEnum.Enum.Retired} />
                      </FormControl>
                      <FormLabel htmlFor="retired" className="leading-5">
                        {employmentTypeEnum.Enum.Retired}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem id="no-employment" value={employmentTypeEnum.Enum['No employment']} />
                      </FormControl>
                      <FormLabel htmlFor="no-employment" className="leading-5">
                        {employmentTypeEnum.Enum['No employment']}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />

            {(employmentType === employmentTypeEnum.Enum.Employed ||
              employmentType === employmentTypeEnum.Enum.Contractor) && (
              <FormField
                control={form.control}
                name="employmentStartDate"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col gap-2 space-y-0">
                    <FormLabel>{applicant2Prefix} Employment start date</FormLabel>
                    <DateInput
                      onChange={(value) => field.onChange(value.toString())}
                      defaultValue={field.value ? parseDate(field.value) : undefined}
                      invalid={fieldState.invalid}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {employmentType === employmentTypeEnum.Enum.Employed && (
              <FormField
                control={form.control}
                name="employedType"
                render={({ field, fieldState }) => (
                  <FormItem className="flex flex-col gap-3 space-y-0">
                    <FormLabel>{applicant2Prefix} How are you employed?</FormLabel>
                    <RadioGroup onValueChange={field.onChange} defaultValue={field.value} invalid={fieldState.invalid}>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem id="Permanent" value={employedTypeEnum.Enum.Permanent} />
                        </FormControl>
                        <FormLabel htmlFor="Permanent" className="leading-5">
                          {employedTypeEnum.Enum.Permanent}
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center gap-2 space-y-0">
                        <FormControl>
                          <RadioGroupItem id="Temporary" value={employedTypeEnum.Enum.Temporary} />
                        </FormControl>
                        <FormLabel htmlFor="Temporary" className="leading-5">
                          {employedTypeEnum.Enum.Temporary}
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {employmentType === employmentTypeEnum.Enum['Self-employed'] && (
              <>
                <FormField
                  control={form.control}
                  name="tradingLengthYears"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-2 space-y-0">
                      <FormLabel>{applicant2Prefix} How long have you been trading?</FormLabel>
                      <Input
                        placeholder="e.g 3"
                        {...field}
                        invalid={fieldState.invalid}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormLabel className="block text-muted-foreground">
                        Round-down to the nearest whole number of year(s)
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="yearsOfIncomeProof"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-3 space-y-0">
                      <div className="flex flex-col gap-1">
                        <FormLabel className="leading-5">
                          {applicant2Prefix} How many years of income proof do you have available?
                        </FormLabel>
                        <FormLabel className="block text-muted-foreground">
                          Years of income proof means you have submitted tax returns to HMRC.
                        </FormLabel>
                      </div>
                      <RadioGroup {...field} onValueChange={field.onChange} invalid={fieldState.invalid}>
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem id="1 year" value={yearsOfIncomeProofEnum.Enum['1 year']} />
                          </FormControl>
                          <FormLabel htmlFor="1 year" className="leading-5">
                            {yearsOfIncomeProofEnum.Enum['1 year']}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem id="2 years+" value={yearsOfIncomeProofEnum.Enum['2 years+']} />
                          </FormControl>
                          <FormLabel htmlFor="2 years+" className="leading-5">
                            {yearsOfIncomeProofEnum.Enum['2 years+']}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {yearsOfIncomeProof && (
                  <>
                    <div className="grid md:grid-cols-2 md:gap-5 gap-10">
                      <FormField
                        control={form.control}
                        name="grossIncomeRecentYear"
                        render={({ field, fieldState }) => (
                          <FormItem className="flex flex-col gap-2 space-y-0">
                            <FormLabel>
                              {applicant2Prefix} <b>GROSS</b> taxable income for most <b>recent</b> tax year
                            </FormLabel>
                            <Input
                              placeholder="e.g 30000"
                              {...field}
                              invalid={fieldState.invalid}
                              onChange={(e) => onNumberInputChange(e, field)}
                            />
                            <FormLabel className="block text-muted-foreground">
                              Including Director&apos;s salary and share of profits after expenses and before tax
                            </FormLabel>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="netIncomeRecentYear"
                        render={({ field, fieldState }) => (
                          <FormItem className="flex flex-col gap-2 space-y-0">
                            <FormLabel>
                              {applicant2Prefix} <b>NET</b> taxable income for most <b>recent</b> tax year
                            </FormLabel>
                            <Input
                              placeholder="e.g 30000"
                              {...field}
                              invalid={fieldState.invalid}
                              onChange={(e) => onNumberInputChange(e, field)}
                            />
                            <FormLabel className="block text-muted-foreground">After expenses and tax</FormLabel>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    {yearsOfIncomeProof === yearsOfIncomeProofEnum.Enum['2 years+'] && (
                      <div className="grid md:grid-cols-2 md:gap-5 gap-10">
                        <FormField
                          control={form.control}
                          name="grossIncomePreviousYear"
                          render={({ field, fieldState }) => (
                            <FormItem className="flex flex-col gap-2 space-y-0">
                              <FormLabel>
                                {applicant2Prefix} <b>GROSS</b> taxable income for <b>previous</b> tax year
                              </FormLabel>
                              <Input
                                placeholder="e.g 30000"
                                {...field}
                                invalid={fieldState.invalid}
                                onChange={(e) => onNumberInputChange(e, field)}
                              />
                              <FormLabel className="block text-muted-foreground">
                                Including Director&apos;s salary and share of profits after expenses and before tax
                              </FormLabel>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="netIncomePreviousYear"
                          render={({ field, fieldState }) => (
                            <FormItem className="flex flex-col gap-2 space-y-0">
                              <FormLabel>
                                {applicant2Prefix} <b>NET</b> taxable income for <b>previous</b> tax year
                              </FormLabel>
                              <Input
                                placeholder="e.g 30000"
                                {...field}
                                invalid={fieldState.invalid}
                                onChange={(e) => onNumberInputChange(e, field)}
                              />
                              <FormLabel className="block text-muted-foreground">After expenses and tax</FormLabel>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {employmentType === employmentTypeEnum.Enum.Contractor && (
              <>
                <FormField
                  control={form.control}
                  name="monthsLeftOnContract"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-2 space-y-0">
                      <FormLabel>{applicant2Prefix} How many months are left on your contract?</FormLabel>

                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <SelectTrigger invalid={fieldState.invalid}>
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={monthsLeftOnContractEnum.Enum['Under 3 months']}>
                            {monthsLeftOnContractEnum.Enum['Under 3 months']}
                          </SelectItem>
                          <SelectItem value={monthsLeftOnContractEnum.Enum['3-6 months']}>
                            {monthsLeftOnContractEnum.Enum['3-6 months']}
                          </SelectItem>
                          <SelectItem value={monthsLeftOnContractEnum.Enum['6-12 months']}>
                            {monthsLeftOnContractEnum.Enum['6-12 months']}
                          </SelectItem>
                          <SelectItem value={monthsLeftOnContractEnum.Enum['12 months+']}>
                            {monthsLeftOnContractEnum.Enum['12 months+']}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="willContractBeRenewed"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-3 space-y-0">
                      <FormLabel className="leading-5">
                        {applicant2Prefix} Will this contract be renewed for at least another 6 months?
                      </FormLabel>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        invalid={fieldState.invalid}
                      >
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={trueFalseEnum.enum.true} />
                          </FormControl>
                          <FormLabel className="leading-5">{yesNoEnum.Enum.Yes}</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={trueFalseEnum.enum.false} />
                          </FormControl>
                          <FormLabel className="leading-5">{yesNoEnum.Enum.No}</FormLabel>
                        </FormItem>
                      </RadioGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}

            {(employmentType === employmentTypeEnum.Enum.Employed ||
              employmentType === employmentTypeEnum.Enum.Contractor) && (
              <>
                <FormField
                  control={form.control}
                  name="grossAnnualIncome"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-2 space-y-0">
                      <FormLabel>
                        {applicant2Prefix} <b>GROSS</b> Annual Income (including guaranteed allowances and benefits,
                        before deductions such as tax and NI)
                      </FormLabel>
                      <Input
                        placeholder="e.g 30000"
                        {...field}
                        invalid={fieldState.invalid}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormLabel className="block text-muted-foreground">
                        Please only include bonuses and commissions if these have been consistent over the last 2 years
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="netMonthlyIncome"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-2 space-y-0">
                      <FormLabel>
                        {applicant2Prefix} <b>NET</b> Monthly Income (take home income after deductions such as tax, NI
                        and student loan payments)
                      </FormLabel>
                      <Input
                        placeholder="e.g 2000"
                        {...field}
                        invalid={fieldState.invalid}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            {(employmentType === employmentTypeEnum.Enum.Employed ||
              employmentType === employmentTypeEnum.Enum['Self-employed'] ||
              employmentType === employmentTypeEnum.Enum.Contractor) && (
              <>
                <FormField
                  control={form.control}
                  name="anyOtherEmployment"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-3 space-y-0">
                      <FormLabel className="leading-5">{applicant2Prefix} Do you have any other employment?</FormLabel>
                      <RadioGroup
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                        invalid={fieldState.invalid}
                      >
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={trueFalseEnum.enum.true} id="anyOtherEmployment-1-Yes" />
                          </FormControl>
                          <FormLabel htmlFor="anyOtherEmployment-1-Yes" className="leading-5">
                            {yesNoEnum.Enum.Yes}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center gap-2 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={trueFalseEnum.enum.false} id="anyOtherEmployment-1-No" />
                          </FormControl>
                          <FormLabel htmlFor="anyOtherEmployment-1-No" className="leading-5">
                            {yesNoEnum.Enum.No}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {anyOtherEmployment === 'true' && (
                  <FormField
                    control={form.control}
                    name="otherEmploymentDetails"
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>Please provide more details about your other employment</FormLabel>
                        <Textarea placeholder="Enter employment details" invalid={fieldState.invalid} {...field} />
                        <FormDescription>
                          This should include the start date of your employment, employment type, gross income and net
                          income
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </>
            )}

            <FormField
              control={form.control}
              name="anyOtherIncome"
              render={({ field, fieldState }) => (
                <FormItem className="flex flex-col gap-3 space-y-0">
                  <FormLabel className="leading-5">{applicant2Prefix} Do you receive any other income?</FormLabel>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    invalid={fieldState.invalid}
                    className="flex flex-col gap-2"
                  >
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={trueFalseEnum.enum.true} id="anyOtherIncome-yes" />
                      </FormControl>
                      <FormLabel className="leading-5" htmlFor="anyOtherIncome-yes">
                        {yesNoEnum.Enum.Yes}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <RadioGroupItem value={trueFalseEnum.enum.false} id="anyOtherIncome-no" />
                      </FormControl>
                      <FormLabel className="leading-5" htmlFor="anyOtherIncome-no">
                        {yesNoEnum.Enum.No}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              )}
            />

            {anyOtherIncome === 'true' && (
              <>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <FormLabel className="leading-5">
                      {applicant2Prefix} What type(s) of other income are you receiving?
                    </FormLabel>
                    <FormLabel className="leading-5 block text-muted-foreground mb-2">Tick all that apply</FormLabel>
                  </div>
                  <div className="flex flex-col gap-2">
                    {otherIncomeTypes.map((type, i, arr) => (
                      <FormField
                        key={type}
                        control={form.control}
                        name="otherIncomeTypes"
                        render={({ field }) => {
                          const isChecked = field?.value?.includes(type);

                          return (
                            <FormItem className="flex flex-col gap-0 col-span-2 py-1">
                              <div className="flex items-center space-x-2">
                                <Checkbox
                                  id={type}
                                  checked={isChecked}
                                  onCheckedChange={(checked) => {
                                    const currentValues = Array.isArray(field.value) ? field.value : [];
                                    return field.onChange(
                                      checked ? [...currentValues, type] : field?.value?.filter((val) => val !== type)
                                    );
                                  }}
                                />

                                <FormLabel htmlFor={type}>{type}</FormLabel>
                              </div>
                              {i + 1 === arr.length && <FormMessage />}
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="totalOtherIncomeAverage"
                  render={({ field, fieldState }) => (
                    <FormItem className="flex flex-col gap-2 space-y-0">
                      <FormLabel>{applicant2Prefix} What is the total other income on average each month?</FormLabel>
                      <Input
                        placeholder="e.g 500"
                        {...field}
                        invalid={fieldState.invalid}
                        onChange={(e) => onNumberInputChange(e, field)}
                      />
                      <FormLabel className="block text-muted-foreground">
                        We can only take the income if it has been received over the last 12 months and will continue
                        after completion.
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
            {Object.keys(form.formState.errors).length > 0 && <Error />}
          </CardContent>
          <Separator className="my-4" />
          <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
            <Button variant="outline" type="button" className="w-full md:w-auto" size="lg" onClick={() => onBack()}>
              Back
            </Button>
            <Button type="submit" className="w-full md:w-auto" size="lg">
              Next
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
