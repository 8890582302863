import { DipApplicationFormProvider } from '@/features/dip/context/dipApplicationFormContext';
import { DipApplicationForm } from '@/features/dip/dipApplicationForm';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const dipQueryParameters = z.object({
  applicationId: z.string().catch(''),
  agreementReference: z.number().catch(1),
});

export const Route = createFileRoute('/_authenticated/_pfida/dip')({
  component: () => (
    <DipApplicationFormProvider>
      <DipApplicationForm />
    </DipApplicationFormProvider>
  ),
  validateSearch: dipQueryParameters,
});
