import { http } from 'msw';
import { createGetUserApplicationsQueryResponse } from '../mocks/createGetUserApplications';

export const getUserApplicationsHandler = http.get('*/user/applications', function handler(info) {
  return new Response(JSON.stringify(createGetUserApplicationsQueryResponse()), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
