import { BaseUrl } from '../../types';
import { createAxiosClient } from '../../utils/createAxiosClient';
import { getBaseUrl } from '../../utils/getBaseUrl';

const baseUrl: BaseUrl = {
  production: 'https://dip-component.home-finance-underwriting.pfida.com',
  development: 'https://dip-component.home-finance-underwriting.dev.pfida.com',
};

export const dipComponentClient = createAxiosClient({ baseURL: getBaseUrl(baseUrl), serviceName: 'DIP_COMPONENT' });
export default dipComponentClient;
