import { invariant } from '@repo/utils';
import { createContext, useContext, useMemo, useState } from 'react';
import { type ApplicantXEmploymentSchema } from '../schema/applicantXEmploymentSchema';
import { type DetailsSchema } from '../schema/detailsSchema';
import { type OutgoingsAndCommitmentsSchema } from '../schema/outgoingsAndCommitmentsSchema';
import { type PropertyAndFinanceSchema } from '../schema/propertyAndFinanceSchema';
import { type TimeframeSchema } from '../steps/timeframe';

export type DipApplicationFormState = {
  timeFrame: Partial<TimeframeSchema>;
  userDetails: Partial<DetailsSchema>;
  applicantOneEmploymentDetails: Partial<ApplicantXEmploymentSchema>;
  applicantTwoEmploymentDetails?: Partial<ApplicantXEmploymentSchema>;
  outgoingsAndCommitments: Partial<OutgoingsAndCommitmentsSchema>;
  propertyAndFinance: Partial<PropertyAndFinanceSchema>;
};

export type Step =
  | 'start'
  | 'timeFrame'
  | 'userDetails'
  | 'applicantOneEmploymentDetails'
  | 'applicantTwoEmploymentDetails'
  | 'outgoingsAndCommitments'
  | 'propertyAndFinance'
  | 'end';

export type ChangeStep = (step: Step, pageNumber: number) => void;

export type UpdateFormState = <T extends Exclude<Step, 'start' | 'end'>>(
  step: T,
  data: DipApplicationFormState[T]
) => void;

export type DipApplicationFormContextState = {
  formState: DipApplicationFormState;
  updateFormState: UpdateFormState;
  currentStep: Step;
  changeCurrentStep: ChangeStep;
  currentPageNumber: number;
};

const DipApplicationFormContext = createContext<DipApplicationFormContextState | null>(null);

const defaultFormState: DipApplicationFormState = {
  timeFrame: {},
  userDetails: {},
  applicantOneEmploymentDetails: {},
  applicantTwoEmploymentDetails: undefined,
  outgoingsAndCommitments: {},
  propertyAndFinance: {},
};

export function DipApplicationFormProvider({ children }: React.PropsWithChildren) {
  const [formState, setFormState] = useState<DipApplicationFormState>(defaultFormState);
  const [currentStep, setCurrentStep] = useState<Step>('start');
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const updateFormState: UpdateFormState = (step, data) => {
    setFormState((value) => ({ ...value, [step]: data }));
  };

  const changeCurrentStep: ChangeStep = (step, pageNumber) => {
    setCurrentPageNumber(pageNumber);
    setCurrentStep(step);
  };

  const value = useMemo(
    () => ({
      formState,
      updateFormState,
      currentStep,
      changeCurrentStep,
      currentPageNumber,
    }),
    [currentStep, formState, currentPageNumber]
  );

  return <DipApplicationFormContext.Provider value={value}>{children}</DipApplicationFormContext.Provider>;
}

export function useDipApplicationFormContext() {
  const context = useContext(DipApplicationFormContext);

  invariant(context, 'useDipApplicationFormContext must be used within a DipApplicationFormContext');

  return context;
}
