import { http } from 'msw';
import { createPutUserApplicationAgreementreferenceApplicationidMutationResponse } from '../mocks/createPutUserApplicationAgreementreferenceApplicationid';

export const putUserApplicationAgreementreferenceApplicationidHandler = http.put(
  '*/user/application/:agreementReference/:applicationId',
  function handler(info) {
    return new Response(JSON.stringify(createPutUserApplicationAgreementreferenceApplicationidMutationResponse()), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
