import { z } from 'zod';

/**
 * @description Success response containing the ID of the created application
 */
export const getUserApplications200Schema = z.array(
  z.object({ applicationId: z.string(), productName: z.string(), agreementReference: z.string(), status: z.string() })
);
/**
 * @description Default error response
 */
export const getUserApplications500Schema = z.object({ error: z.enum(['Internal Server Error']) });
/**
 * @description Success response containing the ID of the created application
 */
export const getUserApplicationsQueryResponseSchema = z.array(
  z.object({ applicationId: z.string(), productName: z.string(), agreementReference: z.string(), status: z.string() })
);
