import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@pfida-finance/ui/components';
import { useForm } from 'react-hook-form';

import { z } from 'zod';
import { Error } from '../components/error';
import { useDipApplicationFormContext } from '../context/dipApplicationFormContext';

const timeframeSchema = z.object({
  timeframe: z.string({
    required_error: 'Timeframe for finance is required',
  }),
});

export type TimeframeSchema = z.infer<typeof timeframeSchema>;

export function Timeframe() {
  const {
    changeCurrentStep: changeStep,
    formState,
    updateFormState,
    currentPageNumber,
  } = useDipApplicationFormContext();

  const form = useForm<TimeframeSchema>({
    resolver: zodResolver(timeframeSchema),
    defaultValues: formState.timeFrame,
  });

  const onSubmit = (data: TimeframeSchema) => {
    updateFormState('timeFrame', data);
    changeStep('userDetails', currentPageNumber + 1);
  };

  return (
    <Card className="flex flex-col w-full p-8 gap-5">
      <CardHeader className="p-0 space-y-0 gap-2">
        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
          <div className="flex items-center justify-center text-sm font-bold h-6 w-6 rounded-full bg-accent text-accent-foreground">
            {currentPageNumber}
          </div>
          Timeframe
        </CardTitle>
        <div className="py-4">
          <Separator />
        </div>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="flex flex-col p-0 gap-6">
            <FormField
              control={form.control}
              name="timeframe"
              render={({ field, fieldState }) => (
                <FormItem className="col-span-2">
                  <FormLabel>How soon would you be looking to obtain finance?</FormLabel>
                  <Select {...field} onValueChange={field.onChange}>
                    <SelectTrigger invalid={fieldState.invalid}>
                      <SelectValue placeholder="Select timeframe" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="ASAP">ASAP</SelectItem>
                      <SelectItem value="6-12 months">6-12 months</SelectItem>
                      <SelectItem value="12-18 months">12-18 months</SelectItem>
                      <SelectItem value="24 months +">24 months +</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormLabel className="leading-5 block text-muted-foreground">
                    Decision in Principle (DiP) is valid for 3 months. Please contact us three months before you are
                    ready and only fill in this form when you are ready.
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            {Object.keys(form.formState.errors).length > 0 && <Error />}
          </CardContent>
          <Separator className="my-4" />
          <CardFooter className="flex flex-col-reverse p-0 gap-3 md:flex-row md:justify-end">
            <Button
              variant="outline"
              className="w-full md:w-auto"
              size="lg"
              onClick={() => changeStep('start', currentPageNumber - 1)}
            >
              Back
            </Button>
            <Button type="submit" className="w-full md:w-auto" size="lg">
              Next
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
