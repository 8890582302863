import { faker } from '@faker-js/faker';
import type {
  GetUserApplications200,
  GetUserApplications500,
  GetUserApplicationsQueryResponse,
} from '../types/GetUserApplications';

/**
 * @description Success response containing the ID of the created application
 */
export function createGetUserApplications200(): NonNullable<GetUserApplications200> {
  return faker.helpers.arrayElements([
    {
      applicationId: faker.string.alpha(),
      productName: faker.string.alpha(),
      agreementReference: faker.string.alpha(),
      status: faker.string.alpha(),
    },
  ]) as any;
}

/**
 * @description Default error response
 */
export function createGetUserApplications500(): NonNullable<GetUserApplications500> {
  return { error: faker.helpers.arrayElement<any>(['Internal Server Error']) };
}

/**
 * @description Success response containing the ID of the created application
 */
export function createGetUserApplicationsQueryResponse(): NonNullable<GetUserApplicationsQueryResponse> {
  return faker.helpers.arrayElements([
    {
      applicationId: faker.string.alpha(),
      productName: faker.string.alpha(),
      agreementReference: faker.string.alpha(),
      status: faker.string.alpha(),
    },
  ]) as any;
}
