import { QueryClient, useQueryClient } from '@tanstack/react-query';
import type { Invalidations } from './invalidations';

const getInvalidationForMutation = <T extends keyof Invalidations = keyof Invalidations>({
  mutationName,
  queryClient,
}: {
  mutationName: T;
  queryClient: QueryClient;
}) => {
  const invalidations: Partial<Invalidations> = {
    // Here you can add invalidations and possibly query cache updates.
    // EXAMPLE:
    // useSomeQuery: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: ['invalidate some queries'],
    //   });
    // },
  };

  return invalidations[mutationName];
};

export const useInvalidationForMutation = <T extends keyof Invalidations = keyof Invalidations>(mutationName: T) => {
  const queryClient = useQueryClient();

  return getInvalidationForMutation({ mutationName, queryClient });
};
