import { z } from 'zod';

export const putUserApplicationAgreementreferenceApplicationidPathParamsSchema = z.object({
  agreementReference: z.string().describe('Agreement Reference'),
  applicationId: z.string().describe('The Application Id'),
});
/**
 * @description Success response containing the ID of the created application
 */
export const putUserApplicationAgreementreferenceApplicationid200Schema = z.any();
/**
 * @description Bad Request Error
 */
export const putUserApplicationAgreementreferenceApplicationid400Schema = z.object({
  message: z.string().describe('The returned error message'),
  paths: z.array(z.any()).describe('List of details about the bad request error'),
});
/**
 * @description Not Found Error
 */
export const putUserApplicationAgreementreferenceApplicationid404Schema = z.object({
  message: z.string().describe('The returned error message'),
});
/**
 * @description Default error response
 */
export const putUserApplicationAgreementreferenceApplicationid500Schema = z.object({
  error: z.enum(['Internal Server Error']),
});

export const putUserApplicationAgreementreferenceApplicationidMutationRequestSchema = z.object({
  applicantTwoEmploymentDetails: z
    .object({
      employmentType: z.enum(['Employed', 'Self-employed', 'Contractor', 'Retired', 'No employment']),
      netIncomePreviousYear: z.string().optional(),
      grossAnnualIncome: z.string().optional(),
      grossIncomePreviousYear: z.string().optional(),
      yearsOfIncomeProof: z.enum(['1-year', '2-years+']).optional(),
      otherIncomeTypes: z.array(z.string()).optional(),
      employedType: z.enum(['Permanent', 'Temporary']).optional(),
      anyOtherIncome: z.boolean(),
      employmentStartDate: z.string().optional(),
      willContractBeRenewed: z.boolean().optional(),
      tradingLengthYears: z.string().optional(),
      netMonthlyIncome: z.string().optional(),
      anyOtherEmployment: z.boolean().optional(),
      grossIncomeRecentYear: z.string().optional(),
      monthsLeftOnContract: z.enum(['Under 3 months', '3-6 months', '6-12 months', '12 months+']).optional(),
      totalOtherIncomeAverage: z.string().optional(),
      netIncomeRecentYear: z.string().optional(),
    })
    .optional(),
  userDetails: z.object({
    applicantTwoDateOfBirth: z.string().optional(),
    applicantTwoResidentialStatus: z
      .enum(['Renting', 'Living with friends and family', 'Own home', 'Own home with finance'])
      .optional(),
    address: z.string(),
    applicantTwoResidencyStatusOther: z.string().optional(),
    numberOfDependents: z.string().optional(),
    city: z.string(),
    hasSecondApplicant: z.boolean(),
    residencyStatusOther: z.string().optional(),
    postcode: z.string(),
    applicantTwoRelationship: z.enum(['Spouse', 'Parent', 'Child', 'Sibling', 'Friend']).optional(),
    hasDependents: z.boolean(),
    dateOfBirth: z.string(),
    residencyStatus: z.enum(['British Citizen', 'Indefinite Leave to Remain', 'Expat', 'Other']),
    phoneNumber: z.string(),
    residentialStatus: z.enum(['Renting', 'Living with friends and family', 'Own home', 'Own home with finance']),
    name: z.string(),
    applicantTwoMaritalStatus: z
      .enum([
        'Single',
        'Married / Civil Partnership',
        'Widowed',
        'Divorced / Ex-civil Partnership',
        'Engaged',
        'Separated',
      ])
      .optional(),
    email: z.string(),
    maritalStatus: z.enum([
      'Single',
      'Married / Civil Partnership',
      'Widowed',
      'Divorced / Ex-civil Partnership',
      'Engaged',
      'Separated',
    ]),
    applicantTwoSameAddress: z.boolean().optional(),
    applicantTwoResidencyStatus: z.enum(['British Citizen', 'Indefinite Leave to Remain', 'Expat', 'Other']).optional(),
  }),
  timeFrame: z.string(),
  outgoingsAndCommitments: z.object({
    insurance: z.string().min(1),
    subscriptions: z.string().min(1),
    medical: z.string().min(1),
    otherShopping: z.string().min(1),
    totalMonthlyRent: z.enum(['I will not pay any rent/board expense after completion', 'Other']),
    childcareAndSchool: z.string().min(1),
    totalMonthlyPayment: z.string().min(1).optional(),
    financialDifficulty: z.boolean(),
    eatingOut: z.string().min(1),
    utilities: z.string().min(1),
    lifestyle: z.string().min(1),
    childMaintenance: z.string().min(1),
    inRentArrears: z.boolean(),
    totalMonthlyRentOther: z.string().min(1).optional(),
    financialCommitments: z.boolean(),
    holidays: z.string().min(1),
    transfers: z.string().min(1),
    councilTax: z.string().min(1),
    travel: z.string().min(1),
    groceries: z.string().min(1),
    householdShopping: z.string().min(1),
  }),
  propertyAndFinance: z.object({
    propertyLocation: z.enum(['England', 'Scotland', 'Wales']),
    additionalInformation: z.string().optional(),
    signedBy: z.string().min(1),
    downPayment: z.string().min(1),
    estimatedPropertyValue: z.string().min(1),
    financePurpose: z.enum(['New Purchase', 'Refinance Buy-to-let Property', 'Other']),
    productType: z.enum(['Buy-to-let Property Purchase Scheme', 'Home Provision Scheme']),
    termYears: z.string().min(1),
  }),
  applicantOneEmploymentDetails: z.object({
    employmentType: z.enum(['Employed', 'Self-employed', 'Contractor', 'Retired', 'No employment']),
    netIncomePreviousYear: z.string().optional(),
    grossAnnualIncome: z.string().optional(),
    grossIncomePreviousYear: z.string().optional(),
    yearsOfIncomeProof: z.enum(['1-year', '2-years+']).optional(),
    otherIncomeTypes: z.array(z.string()).optional(),
    employedType: z.enum(['Permanent', 'Temporary']).optional(),
    anyOtherIncome: z.boolean(),
    employmentStartDate: z.string().optional(),
    willContractBeRenewed: z.boolean().optional(),
    tradingLengthYears: z.string().optional(),
    netMonthlyIncome: z.string().optional(),
    anyOtherEmployment: z.boolean().optional(),
    grossIncomeRecentYear: z.string().optional(),
    monthsLeftOnContract: z.enum(['Under 3 months', '3-6 months', '6-12 months', '12 months+']).optional(),
    totalOtherIncomeAverage: z.string().optional(),
    netIncomeRecentYear: z.string().optional(),
  }),
});

export const putUserApplicationAgreementreferenceApplicationidMutationResponseSchema = z.any();
